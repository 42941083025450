import { useSelector } from 'react-redux';
import UpdateCampaignApproval from '../../../common/campaign-approval/UpdateCampaignApproval';
import { AdminAdvertisingLayout } from '../../../../common/components';
import { getUser } from '../../../../common/store';

const AdminUpdateCampaignApproval = () => {
    const user = useSelector(getUser);
    const orgId = user?.org?.id;

    return (
        <AdminAdvertisingLayout title="Aprovaçção de campanha">
            <UpdateCampaignApproval orgId={orgId} />
        </AdminAdvertisingLayout>
    );
};
export default AdminUpdateCampaignApproval;
