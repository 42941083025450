import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import Datepicker from 'react-tailwindcss-datepicker';
import InputContainer from './InputContainer';

type Props = {
    name: string;
    label?: string;
    className?: string;
    error?: FieldError;
    value: any;
    onChange?: any;
    useRange?: boolean;
    tooltip?: string;
};

const DatePickerRangeInput: FC<Props> = ({
    name,
    label,
    className,
    error,
    onChange,
    tooltip,
    ...selectProps
}) => {
    return (
        <InputContainer
            name={name}
            label={label}
            className={className}
            error={error}
            tooltip={tooltip}
        >
            <Datepicker
                onChange={(value: any) => onChange(value)}
                inputClassName={(d) => `${d} max-h-[38px] !rounded`}
                containerClassName={(d) => `${d} max-h-[38px]`}
                {...selectProps}
            />
        </InputContainer>
    );
};

type ControlledInputProps = {
    name: string;
    label?: string;
    className?: string;
    error?: FieldError;
    useRange?: boolean;
    control: Control<any>;
    tooltip?: string;
};

export const DatePickerRangeControlledInput: FC<ControlledInputProps> = ({
    name,
    control,
    label,

    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...field } }) => (
                <DatePickerRangeInput label={label || name} {...props} {...field} />
            )}
        />
    );
};

export default DatePickerRangeInput;
