import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputContainer from './InputContainer';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: string;
    type?: string;
    required?: boolean;
    className?: string;
    control: Control<any>;
    error?: FieldError;
    tooltip?: string;
    inputClassName?: string;
    showLabel?: boolean;
};

export const BaseInput: FC<Omit<Props, 'control'>> = ({
    name,
    label,
    type = 'text',
    required = false,
    className = '',
    error,
    tooltip,
    inputClassName = '',
    showLabel = true,
    ...props
}) => {
    const renderComponent = () => {
        if (type === 'textarea') {
            return renderTextArea();
        }

        return renderInput();
    };

    const renderInput = () => {
        return (
            <input
                id={name}
                type={type}
                className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 ${inputClassName}`}
                required={required}
                {...props}
            />
        );
    };

    const renderTextArea = () => {
        return (
            <textarea
                id={name}
                className={`block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-gray-500 ${inputClassName}`}
                required={required}
                rows={8}
                {...(props as any)}
            >
                {props.value}
            </textarea>
        );
    };

    return (
        <InputContainer
            name={name}
            label={label}
            className={className}
            error={error}
            tooltip={tooltip}
            showLabel={showLabel}
        >
            {renderComponent()}
        </InputContainer>
    );
};

const Input: FC<Props> = ({
    name,
    label,
    type = 'text',
    required = false,
    className = '',
    control,
    error,
    tooltip,
    showLabel = true,
    ...props
}) => {
    const autoComplete = type !== 'text' ? type : undefined;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <BaseInput
                    id={name}
                    label={label}
                    className={className}
                    error={error}
                    tooltip={tooltip}
                    required={required}
                    type={type}
                    autoComplete={autoComplete}
                    showLabel={showLabel}
                    {...props}
                    {...field}
                />
            )}
        />
    );
};

type FileInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    title?: string;
    data?: { name: string };
    accept?: string; // 'audio/*' | 'image/*';
};

export const FileInput: FC<FileInputProps> = ({ data, ...props }) => {
    const name = data?.name;

    return (
        <label>
            <input {...props} id={props.name} type="file" className="hidden" />
            <div className="flex w-full text-gray-900 rounded-md border-0 ring-1 ring-inset ring-gray-300 shadow-sm cursor-pointer placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-400 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-gray-500">
                <div className="px-5 py-2 text-sm font-bold text-white rounded-l-md bg-accent-400 w">
                    Escolher arquivo
                </div>
                <div className="my-1.5 mx-2">{name}</div>
            </div>
            <div className="mt-3 text-sm text-gray-400">{props?.accept}</div>
        </label>
    );
};

export default Input;
