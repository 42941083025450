import { useSelector } from 'react-redux';
import { PointAdvertisingLayout } from '../../../../common/components';
import { getSelectedOrg } from '../../../../common/store';
import UpdateCampaignApproval from '../../../common/campaign-approval/UpdateCampaignApproval';

const PointUpdateCampaignApproval = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PointAdvertisingLayout title="Aprovação de campanha">
            <UpdateCampaignApproval orgId={orgId} />
        </PointAdvertisingLayout>
    );
};
export default PointUpdateCampaignApproval;
