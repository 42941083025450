import { FC } from 'react';

interface ContentPreviewProps {
    content?: AdvertisingContent;
}

const ContentPreview: FC<ContentPreviewProps> = ({ content }) => {
    if (content?.file_type === 'IMAGE') {
        return (
            <div className="mb-8 last:mb-0">
                <p className="mb-2 text-sm font-medium">{content.name}</p>
                <div className="overflow-hidden w-400 h-300">
                    <img
                        src={content.file_url}
                        alt="Conteúdo"
                        className="object-cover w-full h-full" // Ensures the image covers the div area
                    />
                </div>
            </div>
        );
    }

    if (content?.file_type === 'VIDEO') {
        return (
            <div className="mb-8 last:mb-0">
                <p className="mb-2 text-sm font-medium">{content.name}</p>
                <div className="overflow-hidden w-400 h-300">
                    <video className="object-cover w-full h-full" controls>
                        <source src={content.file_url} type={`video/${content.file_ext}`} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        );
    }

    return <></>;
};

export default ContentPreview;
