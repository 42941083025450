import { FC } from 'react';
import { useAdvertisingCampaigns } from '../../../common/hooks';
import AdvertisingCampaignCalendar from './AdvertisingCampaignCalendar';
import { useNavigate } from 'react-router-dom';
import { HeaderEndLink } from '../../../common/components/Table/Table';
import { getAccessType } from '../../../common/store/slices/application';
import { useSelector } from 'react-redux';

interface Props {
    orgId?: string;
}

const AdvertisingCampaign: FC<Props> = ({ orgId }) => {
    const navigate = useNavigate();
    const { advertisingCampaigns } = useAdvertisingCampaigns({
        fetchData: true,
        orgId,
    });

    const accessType = useSelector(getAccessType);
    const asPartner = accessType === 'PARTNER' || (accessType === 'ADMIN' && orgId !== 'ADMIN');

    // DELETE
    // const [advertisingCampaignToDelete, setAdvertisingCampaignToDelete] =
    //     useState<AdvertisingCampaign>();
    // const closeAdvertisingCampaignDeleteModal = () => setAdvertisingCampaignToDelete(undefined);

    // const onConfirmDelete = async () => {
    //     if (!advertisingCampaignToDelete) return;
    //     try {
    //         await deleteAdvertisingCampaign(advertisingCampaignToDelete.id);
    //     } catch (err) {
    //         // try again...
    //     }

    //     setAdvertisingCampaignToDelete(undefined);
    // };

    const activePath = asPartner ? `/orgs/${orgId}` : '';
    const addAdvertisingCampaignHref = `${activePath}/advertising/campaigns/new`;
    const editAdvertisingCampaignHref = `${activePath}/advertising/campaigns/edit`;

    const editAdvertisingCampaign = (event: any) => {
        const campaign = advertisingCampaigns?.find((item) => item.id === event.id);
        navigate(editAdvertisingCampaignHref, { state: { data: campaign } });
    };

    return (
        <>
            <HeaderEndLink
                to={addAdvertisingCampaignHref}
                label="Adicionar campanha"
                className="pt-1.5 pb-3 mb-4 border-b"
            />
            <div className="w-full h-full">
                <AdvertisingCampaignCalendar
                    data={advertisingCampaigns}
                    onSelectEvent={editAdvertisingCampaign}
                />
            </div>
            {/* 
            <DeleteConfirmationModal
                title="Excluir campanha"
                description="Você tem certeza que deseja excluir essa campanha? 
                Essa operação não pode ser desfeita."
                acceptButtonLabel="campanha"
                open={!!advertisingCampaignToDelete}
                closeModal={closeAdvertisingCampaignDeleteModal}
                acceptButtonAction={onConfirmDelete}
            /> */}
        </>
    );
};

export default AdvertisingCampaign;
