import { createId } from '@paralleldrive/cuid2';
import { FC, ReactNode } from 'react';
import {
    Control,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AccentButton, ErrorMessage, Input, SelectInput } from '../../../common/components';
import countryOptions from '../../../common/data/countries.json';
import { useBankAccounts } from '../../../common/hooks';

export type PercentageSplit = {
    id?: string;
    total_percentage: number;
    bank_account_id: string;
};

interface Props {
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    loading?: boolean;
    isEdit?: boolean;
}

const OrgForm: FC<Props> = ({
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    getValues,
    setValue,
    watch,
    loading,
    isEdit = false,
}) => {
    const { bankAccountOptions } = useBankAccounts({ fetchDataOptions: true });

    const addSplit = (e: any) => {
        e?.preventDefault();
        let splits = getValues('platform_split.percentage_splits') || [];
        splits = [...splits, { id: createId(), total_percentage: 0, bank_account_id: '' }];
        setValue('platform_split.percentage_splits', splits);
    };

    const removeSplit = (index: number) => {
        let splits = [...(getValues('platform_split.percentage_splits') || [])];
        splits.splice(index, 1);
        setValue('platform_split.percentage_splits', splits);
    };

    const activeSplits: PercentageSplit[] = watch('platform_split.percentage_splits');
    const canRemoveSplits = (activeSplits?.length || 0) > 1;

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <Section>
                <DescriptionColumn
                    title="Informações da conta de parceiro"
                    description="A conta de parceiro deve possuir região de atuação"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="name"
                        label="Nome da organização"
                        error={errors?.name}
                    />
                    <SelectInput
                        control={control}
                        name="country"
                        label="Região"
                        error={errors?.country}
                        options={countryOptions}
                    />
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Regra de divisão da plataforma"
                    description="Informações das contas da plataforma  (conta
                administradora) onde serão depositadas as comissões"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="platform_split.total_percentage"
                        label="Percentual de comissão da plataforma"
                        type="number"
                        min="0"
                        max="100"
                        error={errors?.platform_split?.total_percentage}
                    />
                    {activeSplits?.map((item, index) => (
                        <div className="flex gap-8" key={item.id}>
                            <div className="flex gap-6 max-w-sm">
                                <Input
                                    control={control}
                                    name={`platform_split.percentage_splits[${index}].total_percentage`}
                                    label="Percentual"
                                    type="number"
                                    min="0"
                                    max="100"
                                    error={
                                        errors?.platform_split?.percentage_splits?.[index]
                                            ?.total_percentage
                                    }
                                />
                                {/* TODO: Change this to select */}
                                <SelectInput
                                    control={control}
                                    name={`platform_split.percentage_splits[${index}].bank_account_id`}
                                    label="Conta Bancária"
                                    error={
                                        errors?.platform_split?.percentage_splits?.[index]
                                            ?.bank_account_id?.value
                                    }
                                    options={bankAccountOptions}
                                />
                            </div>
                            {canRemoveSplits && (
                                <button
                                    className="flex items-end pb-2 text-red-500 cursor-pointer"
                                    onClick={() => removeSplit(index)}
                                >
                                    Excluir
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="flex gap-6 justify-end max-w-sm">
                        <AccentButton className="sm:w-auto sm:px-7" onClick={addSplit}>
                            Adicionar split
                        </AccentButton>
                    </div>
                </FormColumn>
            </Section>

            <Section>
                <DescriptionColumn
                    title="Regra de divisão de tempo de publicidade"
                    description="Informações de divisão de tempo de publicidade
                    da plataforma e parceiros."
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="advertising_platform_time_percentage"
                        label="Percentual de tempo de publicidade da plataforma"
                        type="number"
                        min="0"
                        max="100"
                        error={errors?.advertising_platform_time_percentage}
                    />
                    <Input
                        control={control}
                        name="advertising_org_time_percentage"
                        label="Percentual de tempo de publicidade do parceiro"
                        type="number"
                        error={errors?.advertising_org_time_percentage}
                        disabled={true}
                    />
                </FormColumn>
            </Section>

            <Section>
                <DescriptionColumn
                    title="Regra de divisão de valor de publicidade"
                    description="Informações de divisão de valor monetário de publicidade
                    da plataforma e parceiros."
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="advertising_platform_value_percentage"
                        label="Percentual de valor de publicidade da plataforma"
                        type="number"
                        min="0"
                        max="100"
                        error={errors?.advertising_platform_value_percentage}
                    />
                    <Input
                        control={control}
                        name="advertising_org_value_percentage"
                        label="Percentual de valor de publicidade do parceiro"
                        type="number"
                        disabled={true}
                        error={errors?.advertising_org_value_percentage}
                    />
                </FormColumn>
            </Section>

            {!isEdit && (
                <Section>
                    <DescriptionColumn
                        title="Conta de acesso do parceiro"
                        description="Informações da conta de usuário que terá acesso
              a conta de parceiro"
                    />
                    <FormColumn>
                        <Input
                            control={control}
                            name="partner_user.name"
                            label="Nome"
                            error={errors?.partner_user?.name}
                        />
                        <Input
                            control={control}
                            name="partner_user.email"
                            label="E-mail"
                            error={errors?.partner_user?.email}
                        />
                    </FormColumn>
                </Section>
            )}
            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="/orgs" className="px-7 py-2 font-bold">
                    Cancelar
                </Link>
                <AccentButton type="submit" loading={loading} className="px-4 py-2 ml-3 w-max">
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">{children}</div>;
};

export default OrgForm;
