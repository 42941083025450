import { Navigate } from 'react-router-dom';
import AdminBankAccountList from '../modules/admin/bank-accounts/AdminBankAccountList';
import AdminCreateBankAccount from '../modules/admin/bank-accounts/AdminCreateBankAccount';
import AdminUpdateBankAccount from '../modules/admin/bank-accounts/AdminUpdateBankAccount';
import BatteryList from '../modules/admin/batteries/BatteryList';
import CreateBattery from '../modules/admin/batteries/CreateBattery';
import UpdateBattery from '../modules/admin/batteries/UpdateBattery';
import ClientList from '../modules/admin/clients/ClientList';
import ClientRentList from '../modules/admin/clients/ClientRentList';
import Dashboard from '../modules/admin/dashboard/Dashboard';
import CreateDevice from '../modules/admin/devices/CreateDevice';
import DeviceList from '../modules/admin/devices/DeviceList';
import UpdateDevice from '../modules/admin/devices/UpdateDevice';
import LogList from '../modules/admin/logs/LogList';
import CreateOrg from '../modules/admin/orgs/CreateOrg';
import OrgList from '../modules/admin/orgs/OrgList';
import UpdateOrg from '../modules/admin/orgs/UpdateOrg';
import AdminRentList from '../modules/admin/rents/AdminRentList';
import AdminAccounts from '../modules/admin/settings/AdminAccounts';
import AdminScreenAudios from '../modules/admin/settings/ScreenAudio';
import AdminScreenBacks from '../modules/admin/settings/ScreenBack';
import AdminScreenPlans from '../modules/admin/settings/ScreenPlan';
import AdminScreenRents from '../modules/admin/settings/ScreenRent';
import CreateFaq from '../modules/admin/settings/faq/CreateFaq';
import Faq from '../modules/admin/settings/faq/Faq';
import UpdateFaq from '../modules/admin/settings/faq/UpdateFaq';
import ForgotPassword from '../modules/auth/ForgotPassword';
import Login from '../modules/auth/Login';
import ResetPassword from '../modules/auth/ResetPassword';
import PartnerBankAccountList from '../modules/partner/bank-accounts/PartnerBankAccountList';
import PartnerCreateBankAccount from '../modules/partner/bank-accounts/PartnerCreateBankAccount';
import PartnerUpdateBankAccount from '../modules/partner/bank-accounts/PartnerUpdateBankAccount';
import PartnerBatteryList from '../modules/partner/batteries/PartnerBatteryList';
import PartnerDeviceList from '../modules/partner/devices/PartnerDeviceList';
import PartnerCreateLocation from '../modules/partner/locations/PartnerCreateLocation';
import PartnerLocationList from '../modules/partner/locations/PartnerLocationList';
import PartnerUpdateLocation from '../modules/partner/locations/PartnerUpdateLocation';
import PartnerRentList from '../modules/partner/rents/PartnerRentList';
import PartnerAddUser from '../modules/partner/users/PartnerAddUser';
import PartnerUserList from '../modules/partner/users/PartnerUserList';
// import TransactionList from '../modules/admin/transactions/TransactionList';

import PartnerTransactionList from '../modules/partner/transactions/PartnerTransactionList';
import AdminTransactionList from '../modules/admin/transactions/AdminTransactionList';
import AdvertisingSegment from '../modules/admin/settings/advertising-segment/AdvertisingSegment';
import CreateAdvertisingSegment from '../modules/admin/settings/advertising-segment/CreateAdvertisingSegment';
import UpdateAdvertisingSegment from '../modules/admin/settings/advertising-segment/UpdateAdvertisingSegment';
import PartnerPointList from '../modules/partner/points/PartnerPointList';
import PartnerCreatePoint from '../modules/partner/points/PartnerCreatePoint';
import PartnerUpdatePoint from '../modules/partner/points/PartnerUpdatePoint';
import AdvertisingContent from '../modules/admin/advertising/advertising-content/AdminAdvertisingContent';
import CreateAdvertisingContent from '../modules/admin/advertising/advertising-content/AdminCreateAdvertisingContent';
import UpdateAdvertisingContent from '../modules/admin/advertising/advertising-content/AdminUpdateAdvertisingContent';
import PartnerAdvertisingContentList from '../modules/partner/advertising-contents/PartnerAdvertisingContentList';
import PartnerCreateAdvertisingContent from '../modules/partner/advertising-contents/PartnerCreateAdvertisingContent';
import PartnerUpdateAdvertisingContent from '../modules/partner/advertising-contents/PartnerUpdateAdvertisingContent';
import CommandLogList from '../modules/admin/command-logs/CommandLogList';
import AdminAdvertisingCampaign from '../modules/admin/advertising/advertising-campaign/AdminAdvertisingCampaign';
import AdminCreateAdvertisingCampaign from '../modules/admin/advertising/advertising-campaign/AdminCreateAdvertisingCampaign';
import AdminUpdateAdvertisingCampaign from '../modules/admin/advertising/advertising-campaign/AdminUpdateAdvertisingCampaign';
import PartnerAdvertisingCampaign from '../modules/partner/advertising-campaign/PartnerAdvertisingCampaign';
import PartnerCreateAdvertisingCampaign from '../modules/partner/advertising-campaign/PartnerCreateAdvertisingCampaign';
import PartnerUpdateAdvertisingCampaign from '../modules/partner/advertising-campaign/PartnerUpdateAdvertisingCampaign';
import PartnerCampaignApprovalList from '../modules/partner/campaign-approval/PartnerCampaignApprovalList';
import PartnerUpdateCampaignApproval from '../modules/partner/campaign-approval/PartnerUpdateCampaignApproval';
import PointAdvertisingContent from '../modules/point/advertising/advertising-content/PointAdvertisingContent';
import PointCreateAdvertisingContent from '../modules/point/advertising/advertising-content/PointCreateAdvertisingContent';
import PointUpdateAdvertisingContent from '../modules/point/advertising/advertising-content/PointUpdateAdvertisingContent';
import PointAdvertisingCampaign from '../modules/point/advertising/advertising-campaign/PointAdvertisingCampaign';
import PointCreateAdvertisingCampaign from '../modules/point/advertising/advertising-campaign/PointCreateAdvertisingCampaign';
import PointUpdateAdvertisingCampaign from '../modules/point/advertising/advertising-campaign/PointUpdateAdvertisingCampaign';
import PointCampaignApprovalList from '../modules/point/advertising/campaign-approval/PointCampaignApprovalList';
import PointUpdateCampaignApproval from '../modules/point/advertising/campaign-approval/PointUpdateCampaignApproval';
import AdminCampaignApprovalList from '../modules/admin/advertising/campaign-approval/AdminCampaignApprovalList';
import AdminUpdateCampaignApproval from '../modules/admin/advertising/campaign-approval/AdminUpdateCampaignApproval';

const _redirectRoute = { path: '*', element: <Navigate to="/" /> };

export const guestRoutes = [
    { path: '/', element: <Login /> },
    { path: '/auth/forgot-password', element: <ForgotPassword /> },
    { path: '/auth/reset-password', element: <ResetPassword /> },
    _redirectRoute,
];

const _partnerRoutes = [
    { path: '/', element: <PartnerDeviceList /> },
    // [PARTNER] DEVICE LIST
    { path: '/orgs/:orgId/devices', element: <PartnerDeviceList /> },
    // [PARTNER] BATTERY LIST
    { path: '/orgs/:orgId/batteries', element: <PartnerBatteryList /> },
    // [PARTNER] USERS CRUD
    { path: '/orgs/:orgId/users', element: <PartnerUserList /> },
    { path: '/orgs/:orgId/users/new', element: <PartnerAddUser /> },
    // [PARTNER] BANK ACCOUNT CRUD
    { path: '/orgs/:orgId/bank-accounts', element: <PartnerBankAccountList /> },
    {
        path: '/orgs/:orgId/bank-accounts/new',
        element: <PartnerCreateBankAccount />,
    },
    {
        path: '/orgs/:orgId/bank-accounts/edit',
        element: <PartnerUpdateBankAccount />,
    },
    // [PARTNER] RENT LIST
    { path: '/orgs/:orgId/rents', element: <PartnerRentList /> },
    // [PARTNER] LOCATION CRUD
    { path: '/orgs/:orgId/locations', element: <PartnerLocationList /> },
    { path: '/orgs/:orgId/locations/new', element: <PartnerCreateLocation /> },
    { path: '/orgs/:orgId/locations/edit', element: <PartnerUpdateLocation /> },
    // [PARTNER] TRANSACTIONS
    { path: '/orgs/:orgId/transactions', element: <PartnerTransactionList /> },
    // [PARTNER] ADVERTISING POINTS
    { path: '/orgs/:orgId/advertising/points', element: <PartnerPointList /> },
    { path: '/orgs/:orgId/advertising/points/new', element: <PartnerCreatePoint /> },
    { path: '/orgs/:orgId/advertising/points/edit', element: <PartnerUpdatePoint /> },
    // [PARTNER] ADVERTISING CONTENTS
    { path: '/orgs/:orgId/advertising/contents', element: <PartnerAdvertisingContentList /> },
    { path: '/orgs/:orgId/advertising/contents/new', element: <PartnerCreateAdvertisingContent /> },
    {
        path: '/orgs/:orgId/advertising/contents/edit',
        element: <PartnerUpdateAdvertisingContent />,
    },
    // [PARTNER] ADVERTISING CAMPAIGNS
    { path: '/orgs/:orgId/advertising/campaigns', element: <PartnerAdvertisingCampaign /> },
    {
        path: '/orgs/:orgId/advertising/campaigns/new',
        element: <PartnerCreateAdvertisingCampaign />,
    },
    {
        path: '/orgs/:orgId/advertising/campaigns/edit',
        element: <PartnerUpdateAdvertisingCampaign />,
    },
    // [PARTNER] CAMPAIGN APPROVALS
    { path: '/orgs/:orgId/advertising/approvals', element: <PartnerCampaignApprovalList /> },
    {
        path: '/orgs/:orgId/advertising/approvals/edit',
        element: <PartnerUpdateCampaignApproval />,
    },
];

export const partnerRoutes = [..._partnerRoutes, _redirectRoute];

export const adminRoutes = [
    { path: '/', element: <Dashboard /> },
    // [ADMIN SETTINS] ADMIN USERS
    { path: '/settings/users', element: <AdminAccounts /> },
    // [ADMIN SETTINGS] BANK ACCOUNT CRUD
    { path: '/settings/bank-accounts', element: <AdminBankAccountList /> },
    { path: '/settings/bank-accounts/new', element: <AdminCreateBankAccount /> },
    { path: '/settings/bank-accounts/edit', element: <AdminUpdateBankAccount /> },
    // [ADMIN SETTINGS] SCREEN-* CRUD
    { path: '/settings/screen-audio', element: <AdminScreenAudios /> },
    { path: '/settings/screen-rent', element: <AdminScreenRents /> },
    { path: '/settings/screen-return', element: <AdminScreenBacks /> },
    { path: '/settings/screen-plan', element: <AdminScreenPlans /> },
    // [ADMIN SETTINGS] FAQ
    { path: '/settings/faq', element: <Faq /> },
    { path: '/settings/faq/new', element: <CreateFaq /> },
    { path: '/settings/faq/edit', element: <UpdateFaq /> },
    // [ADMIN SETTINGS] FAQ
    { path: '/settings/advertising-segment', element: <AdvertisingSegment /> },
    { path: '/settings/advertising-segment/new', element: <CreateAdvertisingSegment /> },
    { path: '/settings/advertising-segment/edit', element: <UpdateAdvertisingSegment /> },
    // [ADMIN] ORG CRUD
    { path: '/orgs', element: <OrgList /> },
    { path: '/orgs/new', element: <CreateOrg /> },
    { path: '/orgs/edit', element: <UpdateOrg /> },
    // [ADMIN] DEVICE CRUD
    { path: '/devices', element: <DeviceList /> },
    { path: '/devices/new', element: <CreateDevice /> },
    { path: '/devices/edit', element: <UpdateDevice /> },
    // [ADMIN] BATTERY CRUD
    { path: '/batteries', element: <BatteryList /> },
    { path: '/batteries/new', element: <CreateBattery /> },
    { path: '/batteries/edit', element: <UpdateBattery /> },
    // [AMDIN] CLIENT LIST
    { path: '/clients', element: <ClientList /> },
    { path: '/clients/:clientId/rents', element: <ClientRentList /> },
    // [AMDIN] LOG LIST
    { path: '/logs', element: <LogList /> },
    // [AMDIN] COMMAND LOG LIST
    { path: '/command-logs', element: <CommandLogList /> },
    // [AMDIN] RENT LIST
    { path: '/rents', element: <AdminRentList /> },
    // [AMDIN] TRANSACTION LIST
    { path: '/transactions', element: <AdminTransactionList /> },
    // [AMDIN ADS] PONTOS
    // [ADMIN ADS] CONTEUDOS
    { path: '/advertising/contents', element: <AdvertisingContent /> },
    { path: '/advertising/contents/new', element: <CreateAdvertisingContent /> },
    { path: '/advertising/contents/edit', element: <UpdateAdvertisingContent /> },
    // [ADMIN ADS] CAMPANHAS
    { path: '/advertising/campaigns', element: <AdminAdvertisingCampaign /> },
    { path: '/advertising/campaigns/new', element: <AdminCreateAdvertisingCampaign /> },
    { path: '/advertising/campaigns/edit', element: <AdminUpdateAdvertisingCampaign /> },

    // [AMDIN ADS] APROVACOES
    { path: '/advertising/approvals', element: <AdminCampaignApprovalList /> },
    { path: '/advertising/approvals/edit', element: <AdminUpdateCampaignApproval /> },
    ..._partnerRoutes,
    _redirectRoute,
];

export const pointRoutes = [
    { path: '/', element: <Navigate to="/advertising/contents" /> },
    // [POINT ADS] CONTEUDOS
    { path: '/advertising/contents', element: <PointAdvertisingContent /> },
    { path: '/advertising/contents/new', element: <PointCreateAdvertisingContent /> },
    { path: '/advertising/contents/edit', element: <PointUpdateAdvertisingContent /> },
    // [POINT ADS] CAMPANHAS
    { path: '/advertising/campaigns', element: <PointAdvertisingCampaign /> },
    { path: '/advertising/campaigns/new', element: <PointCreateAdvertisingCampaign /> },
    { path: '/advertising/campaigns/edit', element: <PointUpdateAdvertisingCampaign /> },
    // [POINT ADS] APROVAÇÕES
    { path: '/advertising/approvals', element: <PointCampaignApprovalList /> },
    { path: '/advertising/approvals/edit', element: <PointUpdateCampaignApproval /> },
    // MISC
    _redirectRoute,
];
