import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';
import { useSelector } from 'react-redux';
import { getUser } from '../store';
import { getAccessType } from '../store/slices/application';

const useAdvertisingContents = (orgId?: string) => {
    const [advertisingContents, setAdvertisingContents] = useState<{
        meta: any;
        data: any[];
    }>();
    const { get, post, patch, _delete, loading, error } = useRequests();

    const user = useSelector(getUser);
    const accessType = useSelector(getAccessType);
    const activeOrg = orgId || user?.org?.id;

    const fetchAdvertisingContents = async ({ page, search }: FetchParams = {}) => {
        let route = '/partner/advertising-contents?';
        const activeOrg = orgId || user?.org?.id;

        if (accessType === 'POINT') {
            route += `pointId=${user?.id}&`;
        } else {
            route += `orgId=${activeOrg}&`;
        }

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;

            setAdvertisingContents(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createAdvertisingContent = async (payload: any) => {
        let route = '/partner/advertising-contents';

        const _payload = { ...payload, org_id: activeOrg };

        if (accessType === 'POINT') {
            delete _payload.org_id;
        }

        try {
            const formData = buildFormDataFromPayload(_payload);
            await post(route, formData);
            setTimeout(() => toast.success('Conteudo de publicidade criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateAdvertisingContent = async (id: string, payload: any) => {
        let route = `/partner/advertising-contents/${id}`;

        if (accessType === 'POINT') {
            delete payload.org_id;
        }

        try {
            const formData = buildFormDataFromPayload(payload);
            await patch(route, formData);
            setTimeout(() => toast.success('Conteudo de publicidade alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const buildFormDataFromPayload = (payload: any) => {
        // to upload file
        const formData = new FormData();

        for (const entry of Object.entries(payload)) {
            formData.append(entry[0], entry[1] as any);
        }

        return formData;
    };

    const deleteAdvertisingContent = async (id: string) => {
        let route = `/partner/advertising-contents/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Conteudo de publicidade removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (!activeOrg) return;

        fetchAdvertisingContents();

        // eslint-disable-next-line
    }, []);

    return {
        advertisingContents: advertisingContents,
        fetchAdvertisingContents,
        createAdvertisingContent,
        updateAdvertisingContent,
        deleteAdvertisingContent,
        error,
        loading,
    };
};

export default useAdvertisingContents;
