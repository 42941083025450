import { useSelector } from 'react-redux';
import CampaignApprovalList from '../../../common/campaign-approval/CampaignApprovalList';
import { getUser } from '../../../../common/store';
import { AdminAdvertisingLayout } from '../../../../common/components';

const AdminCampaignApprovalList = () => {
    const user = useSelector(getUser);
    const orgId = user?.org?.id;

    return (
        <AdminAdvertisingLayout title="Aprovações de campanha">
            <CampaignApprovalList orgId={orgId} />
        </AdminAdvertisingLayout>
    );
};

export default AdminCampaignApprovalList;
