import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import { getSelectedOrg } from '../../../common/store';
import UpdateCampaignApproval from '../../common/campaign-approval/UpdateCampaignApproval';

const PartnerUpdateCampaignApproval = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PartnerLayoutWithSidebar>
            <UpdateCampaignApproval orgId={orgId} />
        </PartnerLayoutWithSidebar>
    );
};
export default PartnerUpdateCampaignApproval;
