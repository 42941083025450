import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCampaignApprovals } from '../../../common/hooks';
import CampaignApprovalForm from './CampaignApprovalForm';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import * as yup from 'yup';
import { getAccessType } from '../../../common/store/slices/application';
import { useSelector } from 'react-redux';

export const schema = yup
    .object({
        approvals: yup.array().of(
            yup.object().shape({
                location_id: yup.string().required(),
                approved: yup.boolean().nullable().optional(),
                // message: yup.string().nullable().optional(),
                message: yup
                    .string()
                    .when('approved', (approved, schema) =>
                        approved[0] === false
                            ? schema.required(requiredFieldMessage())
                            : schema.optional().nullable(),
                    ),
            }),
        ),
    })
    .required(requiredFieldMessage());

const initialState = {
    approvals: [] as any[],
};

export const payloadFromSchema = (data: typeof initialState) => {
    const statusMap: { [k: string]: string } = {
        undefined: 'PENDING',
        true: 'APPROVED',
        false: 'REJECTED',
    };

    let payload: any = {
        campaign_approval_locations: data.approvals.map((item) => ({
            location_id: item.location_id,
            status: statusMap[`${item.approved}`],
            message: item.approved ? '' : item.message,
        })),
    };

    return payload;
};

const createFormData = (data: CampaignApproval) => {
    const statusMap: { [k: string]: boolean | undefined } = {
        PENDING: undefined,
        APPROVED: true,
        REJECTED: false,
    };

    const approvals = data.campaign_approval_locations.map((item) => ({
        location_id: item.location_id,
        approved: statusMap[item.status as string],
        message: item.message,
    }));

    return { approvals };
};

interface Props {
    orgId?: string;
}

// TODO: whole thing...
const UpdateCampaignApproval: FC<Props> = ({ orgId }) => {
    const { updateCampaignApproval, loading, error } = useCampaignApprovals(orgId);
    const navigate = useNavigate();
    const location = useLocation();

    const campaignApprovalData = location.state?.data;

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: useMemo(
            () => campaignApprovalData && createFormData(campaignApprovalData),
            [campaignApprovalData],
        ),
    });

    useEffect(() => {
        if (campaignApprovalData) {
            reset(createFormData(campaignApprovalData));
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        console.log('PAYLOAD FROM SCHEMA:', payload);

        try {
            await updateCampaignApproval(campaignApprovalData.id, payload);
            const activePath = orgId ? `/orgs/${orgId}` : '';
            navigate(`${activePath}/advertising/approvals`);
        } catch (err) {
            // try again...
        }
    };

    const accessType = useSelector(getAccessType);
    const asPoint = accessType === 'POINT';

    return (
        <>
            <CampaignApprovalForm
                campaignApproval={campaignApprovalData}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                errors={errors} // client-side validation
                error={error} // server-side error
                loading={loading}
                editable={asPoint}
            />
        </>
    );
};

export default UpdateCampaignApproval;
