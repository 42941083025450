import { FC, ReactNode, useMemo } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { AccentButton, BaseInput, ErrorMessage } from '../../../common/components';
import { isoDateToBrl } from '../../../common/utils/date.utils';
import ContentPreview from './ContentPreviewComponent';
import ApprovalsDisplay from './ApprovalsDisplay';
import ApprovalsDisplayForm from './ApprovalsDisplayForm';

interface Props {
    campaignApproval: CampaignApproval;
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    editable?: boolean;
}

const CampaignApprovalForm: FC<Props> = ({
    campaignApproval,
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
    editable = false,
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const campaignPeriod = useMemo(() => {
        if (!campaignApproval) return '';
        const start = campaignApproval.campaign.campaign_start_date as string;
        const end = campaignApproval.campaign.campaign_end_date as string;

        const start_date = isoDateToBrl(start.split('T')[0]);
        const end_date = isoDateToBrl(end.split('T')[0]);

        return `${start_date} ~ ${end_date}`;
    }, [campaignApproval]);

    const campaignContent =
        campaignApproval?.campaign.advertising_campaign_content?.map((item) => item.content) || [];

    const campaignApprovals = campaignApproval?.campaign_approval_locations || [];

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <Section>
                <DescriptionColumn
                    title="Dados da campanha"
                    description="Informações gerais da campanha"
                />
                <FormColumn>
                    <BaseInput
                        value={campaignApproval?.campaign.name}
                        name="name"
                        label="Nome"
                        className="sm:w-full"
                        disabled
                    />
                    <BaseInput
                        value={campaignPeriod}
                        name="period"
                        label="Período"
                        className="sm:w-full"
                        disabled
                    />
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Conteúdo da campanha"
                    description="Videos ou imagens que serão veiculados"
                />
                <FormColumn>
                    {campaignContent.map((content) => (
                        <ContentPreview content={content} />
                    ))}
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Moderação de campanha"
                    description="Realize a aprovação ou reprovação do conteúdo da
campanha"
                />
                <FormColumn>
                    {editable ? (
                        <ApprovalsDisplayForm
                            campaignApprovals={campaignApprovals}
                            control={control}
                            errors={errors}
                        />
                    ) : (
                        <ApprovalsDisplay campaignApprovals={campaignApprovals} />
                    )}
                </FormColumn>
            </Section>
            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                    Cancelar
                </Link>
                <AccentButton
                    type="submit"
                    loading={loading}
                    className="px-4 py-2 ml-3 w-max"
                    disabled={!editable}
                >
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

interface Children {
    children?: ReactNode;
    className?: string;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 w-full md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 w-full md:col-span-2 md:mt-0">{children}</div>;
};

export default CampaignApprovalForm;
