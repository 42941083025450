import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
    orgId: string;
}

const usePoints = ({ fetchData = false, fetchDataOptions = false, orgId }: Options = {} as any) => {
    const [points, setPoints] = useState<{ meta: any; data: any[] }>();
    const { get, post, patch, _delete, loading, error } = useRequests();

    const fetchPoints = async ({ page, search }: FetchParams = {}) => {
        let route = `/partner/points?orgId=${orgId}&`;

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setPoints(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createPoint = async (payload: any) => {
        let route = '/partner/points';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Ponto criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updatePoint = async (id: string, payload: any) => {
        let route = `/partner/points/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Ponto alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deletePoint = async (id: string) => {
        let route = `/partner/points/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Ponto removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchPoints();
        }
        // eslint-disable-next-line
    }, []);

    return {
        points,
        fetchPoints,
        createPoint,
        updatePoint,
        deletePoint,
        error,
        loading,
    };
};

export default usePoints;
