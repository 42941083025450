import { useSelector } from 'react-redux';
import { PointAdvertisingLayout } from '../../../../common/components';
import AdvertisingCampaign from '../../../common/advertising-campaign/AdvertisingCampaign';
import { getUser } from '../../../../common/store';

const AdminAdvertisingCampaign = () => {
    const user = useSelector(getUser);
    const orgId = user?.org?.id;

    return (
        <PointAdvertisingLayout title="Campanhas de publicidade">
            <AdvertisingCampaign orgId={orgId} />
        </PointAdvertisingLayout>
    );
};

export default AdminAdvertisingCampaign;
