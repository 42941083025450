import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../../../../common/store';
import CampaignApprovalList from '../../../common/campaign-approval/CampaignApprovalList';
import { PointAdvertisingLayout } from '../../../../common/components';

const PointCampaignApprovalList = () => {
    const selectedOrg = useSelector(getSelectedOrg);
    const orgId = selectedOrg?.id as string;

    return (
        <PointAdvertisingLayout title="Aprovações de campanha">
            <CampaignApprovalList orgId={orgId} />
        </PointAdvertisingLayout>
    );
};

export default PointCampaignApprovalList;
