import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useAdvertisingCampaigns } from '../../../common/hooks';
import { requiredFieldMessage } from '../../../common/utils/validation.utils';
import AdvertisingCampaignForm from './AdvertisingCampaignForm';
import { formattedMinutesToIntSeconds } from '../../../common/utils/date.utils';
import useAdvertisingCampaignForm from './useAdvertisingCampaignForm';
import { getAccessType } from '../../../common/store/slices/application';
import { useSelector } from 'react-redux';

export const selectItemSchema = yup.object().shape({
    value: yup.string().required(requiredFieldMessage()),
    label: yup.string().required(requiredFieldMessage()),
});

export const optionalSelectItemSchema = yup.object().shape({
    value: yup.string(),
    label: yup.string(),
});

export const schema = yup
    .object({
        name: yup.string().trim().required(requiredFieldMessage()),
        campaign_period: yup.object({
            startDate: yup.string().trim().required(requiredFieldMessage()),
            endDate: yup.string().trim().required(requiredFieldMessage()),
        }),
        campaign_value: yup.string().trim().required(requiredFieldMessage()),
        segment_city_state: yup.array().of(selectItemSchema),
        segment_segment_location: yup.array().of(selectItemSchema),
        max_display_by_hour: yup.string().trim().required(requiredFieldMessage()),
        advertising_campaign_content: yup.array().of(
            yup.object().shape({
                content_id: selectItemSchema.required(requiredFieldMessage()),
                display_duration: yup.string().required(requiredFieldMessage()),
            }),
        ),
        publish: yup.boolean(),
    })
    .required(requiredFieldMessage());

const currentDate = new Date();
const nextWeekDate = new Date();
nextWeekDate.setDate(currentDate.getDate() + 7);

const initialState = {
    name: '',
    campaign_period: {
        startDate: currentDate.toISOString().split('T')[0],
        endDate: nextWeekDate.toISOString().split('T')[0],
    },
    campaign_value: '',
    segment_city_state: [] as SelectOption[],
    segment_segment_location: [] as SelectOption[],
    max_display_by_hour: '',
    advertising_campaign_content: [
        { content_id: null, display_duration: null, fixed_duration: false },
    ] as unknown as {
        content_id: { value: string; label: string };
        display_duration: string;
        fixed_duration: boolean;
    }[],
    publish: false,
};

export const payloadFromSchema = (data: typeof initialState) => {
    let payload: any = {
        ...data,
        max_display_by_hour: Number(data.max_display_by_hour),
        // segments
        segment_city_state: data.segment_city_state.map((item) => item.value).join(';'),
        segment_segment_location: data.segment_segment_location.map((item) => item.value).join(';'),
        // content
        advertising_campaign_content: data.advertising_campaign_content.map((item) => ({
            content_id: item.content_id?.value,
            display_duration: formattedMinutesToIntSeconds(item.display_duration),
        })),
        // period
        campaign_start_date: `${data.campaign_period.startDate}T03:00:00.000Z`,
        campaign_end_date: `${data.campaign_period.endDate}T03:00:00.000Z`,
    };

    return payload;
};

interface Props {
    orgId?: string;
}

const CreateAdvertisingCampaign: FC<Props> = ({ orgId }) => {
    const {
        advertisingContentOptions,
        advertisingUFCityOptions,
        advertisingSegmentOptions,
        campaignInformation,
        resetCampaignInformation,
        calculateCampaignInformation,
        createAdvertisingCampaign,
        loading,
        error,
    } = useAdvertisingCampaigns({
        fetchDataOptions: true,
        orgId,
    });

    const navigate = useNavigate();
    const accessType = useSelector(getAccessType);
    const asPartner = accessType === 'PARTNER' || (accessType === 'ADMIN' && orgId !== 'ADMIN');

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialState,
    });

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        console.log('ON SUBMIT', data);

        // console.log({ payload });
        // return;

        try {
            await createAdvertisingCampaign(payload);
            const activePath = asPartner ? `/orgs/${orgId}` : '';
            navigate(`${activePath}/advertising/campaigns`);
        } catch (err) {
            // try again...
        }
    };

    const { setContentTime, triggerCalculate, disabledContentDurationList } =
        useAdvertisingCampaignForm({
            control,
            watch,
            setValue,
            schema,
            campaignInformation,
            calculateCampaignInformation,
            resetCampaignInformation,
        });

    const _onSubmit = (e?: any) => {
        e?.preventDefault();
        handleSubmit(onSubmit)();
    };

    const _onPublish = (e: any) => {
        e.preventDefault();
        setValue('publish', true);
        _onSubmit();
    };

    return (
        <AdvertisingCampaignForm
            control={control}
            onSubmit={_onSubmit}
            onPublish={_onPublish}
            errors={errors} // client-side validation
            error={error} // server-side error
            loading={loading}
            setContentTime={setContentTime}
            advertisingContentOptions={advertisingContentOptions}
            advertisingUFCityOptions={advertisingUFCityOptions}
            advertisingSegmentOptions={advertisingSegmentOptions}
            campaignInformation={campaignInformation}
            triggerCalculate={triggerCalculate}
            disabledContentDurationList={disabledContentDurationList}
        />
    );
};

export default CreateAdvertisingCampaign;
