import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { FC } from 'react';

interface Props {
    enabled: boolean;
    setEnabled: (a: boolean) => void;
    inverted?: boolean;
    vibrant?: boolean;
    disabled?: boolean;
}

const colors = {
    default: { true: 'bg-green-500', false: 'bg-gray-200', undefined: 'bg-gray-200' },
    inverted: { true: 'bg-red-500', false: 'bg-green-500', undefined: 'bg-gray-200' },
    vibrant: { true: 'bg-green-500', false: 'bg-red-500', undefined: 'bg-gray-200' },
};

const Toggle: FC<Props> = ({ enabled, setEnabled, inverted, vibrant, disabled }) => {
    let selectedCollorPallete = colors.default;
    if (inverted) selectedCollorPallete = colors.inverted;
    if (vibrant) selectedCollorPallete = colors.vibrant;

    const color = selectedCollorPallete[`${enabled}`];

    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={clsx(
                color,
                'inline-flex relative flex-shrink-0 w-11 h-6 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out cursor-pointer focus:outline-none disabled:cursor-default',
            )}
            disabled={disabled}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={clsx(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'inline-block w-5 h-5 bg-white rounded-full ring-0 shadow transition duration-200 ease-in-out transform pointer-events-none',
                )}
            />
        </Switch>
    );
};

export default Toggle;
