export const formatDate = (date?: Date | string | null, includeSeconds?: boolean) => {
    if (!date) return '';
    if (typeof date === 'string') date = new Date(date);

    // Create an options object for formatting
    const options = {
        year: 'numeric' as const,
        month: '2-digit' as const,
        day: '2-digit' as const,
        hour: '2-digit' as const,
        minute: '2-digit' as const,
        second: includeSeconds ? ('2-digit' as const) : undefined,
        // timeZone: 'America/Sao_Paulo',
        hour12: false, // Use 24-hour format
    };

    // Create an Intl.DateTimeFormat object with the desired options and time zone
    const formatter = new Intl.DateTimeFormat('pt-BR', options);

    // Format the date
    const formattedDate = formatter.format(date).replace(/,/, ''); // Remove comma between date and time

    return formattedDate;
};

export const iSOStringWithTimezone = (_date: Date) => {
    return _date
        .toLocaleString('sv', {
            timeZoneName: 'short',
        })
        .split(' ')
        .slice(0, 2)
        .join('T');
};

export const getYear = (_date: string) => {
    if (!_date) return;
    // _date can be of format "06071997" from bank-account-form

    // 06/07/1997 => 1997-07-06
    if (_date.length === 10) {
        _date = _date.split('/').reverse().join('-');
    }

    // 06071997 => 1997-07-06
    if (_date.length === 8) {
        _date = `${_date.slice(4, 8)}-${_date.slice(2, 4)}-${_date.slice(0, 2)}`;
    }

    return new Date(_date).toISOString().split('T')[0];
};

export const secondsToFormatedMinutes = (seconds: number) => {
    const min = Math.floor(seconds / 60)
        .toString()
        .padStart(2, '0');
    const sec = Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0');
    return `${min}:${sec}`;
};

export const secondsToFormatedTime = (seconds: number) => {
    const hour = Math.floor(seconds / (60 * 60))
        .toString()
        .padStart(2, '0');
    const min = Math.floor((seconds / 60) % 60)
        .toString()
        .padStart(2, '0');
    const sec = Math.floor(seconds % 60)
        .toString()
        .padStart(2, '0');
    return `${hour}:${min}:${sec}`;
};

export const minutesToFormatedTime = (minutes: number) => {
    const hour = Math.floor(minutes / 60)
        .toString()
        .padStart(2, '0');
    const min = Math.floor(minutes % 60)
        .toString()
        .padStart(2, '0');
    return `${hour}:${min}:00`;
};

export const validateAge = (date: string, minAge = 18, maxAge = 99) => {
    const today = new Date();
    const birthDate = new Date(date);

    // Check if the date is valid
    if (birthDate.toString() === 'Invalid Date') {
        throw new Error('Invalid date string');
    }

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // If birth month is greater than current month or it's the same month but the birth day hasn't passed yet, reduce age by 1
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age >= minAge && age <= maxAge;
};

// 2024-10-20 to 20/10/2024
export const isoDateToBrl = (date: string) => {
    if (!date) return date;
    return date.split('-').reverse().join('/');
};

// "01:10" => 70
export const formattedMinutesToIntSeconds = (formatted: string) => {
    if (!formatted) return 0;
    if (!formatted.match(/\d\d.*\d\d/)) return 0;

    const minutes = Number(formatted.slice(0, 2));
    const seconds = Number(formatted.slice(-2));

    const int_seconds = minutes * 60 + seconds;
    return int_seconds;
};

export const intSecondsToFormattedMinutes = (int_seconds: number, separator = ':') => {
    const minutes = `${Math.floor(int_seconds / 60)}`.padStart(2, '0');
    const seconds = `${int_seconds % 60}`.padStart(2, '0');

    const formatted = `${minutes}${separator}${seconds}`;
    return formatted;
};
