import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import AdvertisingCampaign from '../../common/advertising-campaign/AdvertisingCampaign';
import { getSelectedOrg, getUser } from '../../../common/store';

const PartnerAdvertisingCampaign = () => {
    const user = useSelector(getUser);
    const userOrgId = user?.org?.id;

    const selectedOrg = useSelector(getSelectedOrg);
    const selectedOrgId = selectedOrg?.id as string;

    return (
        // <PartnerLayoutWithSidebar title="Campanhas de publicidade">
        <PartnerLayoutWithSidebar>
            <AdvertisingCampaign orgId={selectedOrgId || userOrgId} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerAdvertisingCampaign;
