import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';

interface Options {
    fetchData?: boolean;
    fetchDataOptions?: boolean;
    orgId: string;
}

const useLocations = (
    { fetchData = false, fetchDataOptions = false, orgId }: Options = {} as any,
) => {
    const [locations, setLocations] = useState<{ meta: any; data: any[] }>();
    const { get, post, patch, _delete, loading, error } = useRequests();

    const [advertisingSegmentOptions, setAdvertisingSegmentOptions] = useState<SelectOption[]>([]);
    const [pointOptions, setPointOptions] = useState<SelectOption[]>([]);

    const fetchLocations = async ({ page, search }: FetchParams = {}) => {
        let route = `/partner/locations?orgId=${orgId}&`;

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;
            setLocations(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchSegmentOptions = async () => {
        let route = `/partner/advertising-campaigns/segment-options`;

        try {
            const response = await get(route);
            const data = response?.data as { id: string; name: string }[];

            const contentOptions = data?.map((content) => ({
                value: content.id,
                label: content.name,
            }));

            setAdvertisingSegmentOptions(contentOptions);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchPointOptions = async () => {
        let route = `/partner/locations/point-options?orgId=${orgId}`;

        try {
            const response = await get(route);
            const data = response?.data as { id: string; name: string }[];

            const contentOptions = data?.map((content) => ({
                value: content.id,
                label: content.name,
            }));

            setPointOptions(contentOptions);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const createLocation = async (payload: any) => {
        let route = '/partner/locations';

        try {
            await post(route, payload);
            setTimeout(() => toast.success('Local criado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const updateLocation = async (id: string, payload: any) => {
        let route = `/partner/locations/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Local alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const deleteLocation = async (id: string) => {
        let route = `/partner/locations/${id}`;

        try {
            await _delete(route);
            setTimeout(() => toast.success('Local removido com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (fetchData) {
            fetchLocations();
        }

        if (fetchDataOptions) {
            fetchSegmentOptions();
            fetchPointOptions();
        }

        // eslint-disable-next-line
    }, []);

    return {
        locations,
        advertisingSegmentOptions,
        pointOptions,
        fetchLocations,
        createLocation,
        updateLocation,
        deleteLocation,
        error,
        loading,
    };
};

export default useLocations;
