import { FC, ReactNode } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AccentButton, ErrorMessage, Input } from '../../../common/components';

interface Props {
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    title?: string;
    description?: string;
}

const UserForm: FC<Props> = ({
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
    title = 'Conta de acesso do parceiro',
    description = 'Informações da conta de usuário que terá acesso a conta de parceiro',
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <Section>
                <DescriptionColumn title={title} description={description} />
                <FormColumn>
                    <Input control={control} name="name" label="Nome" error={errors?.name} />
                    <Input control={control} name="email" label="E-mail" error={errors?.email} />
                </FormColumn>
            </Section>
            <div className="flex justify-end items-center pt-7 text-sm">
                <div onClick={goBack} className="px-7 py-2 font-bold cursor-pointer">
                    Cancelar
                </div>
                <AccentButton type="submit" loading={loading} className="px-4 py-2 ml-3 w-max">
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">{children}</div>;
};

export default UserForm;
