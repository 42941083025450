import { FC, useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import { Input, ToggleInput } from '../../../common/components';

interface Props {
    campaignApprovals?: Partial<CampaignApprovalLocations>[];
    errors?: any;
    control: Control<any>;
}

const ApprovalsDisplayForm: FC<Props> = ({ campaignApprovals, control, errors }) => {
    const [inputActive, setInputActive] = useState<any>({});

    const toggleInputFor = (ind: number, value: boolean) => {
        setInputActive((old: any) => ({ ...old, [ind]: !value }));
    };

    useEffect(() => {
        if (campaignApprovals) {
            for (let i = 0; i < campaignApprovals?.length; i++) {
                if (campaignApprovals[i].status === 'REJECTED') {
                    toggleInputFor(i, false);
                }
            }
        }
    }, [campaignApprovals]);

    return (
        <div className="w-full text-sm">
            <div className="flex justify-between pt-4 pb-4 w-full font-bold border-b last:pb-0 first:pt-0 last:border-b-0">
                <p>Local</p>
                <p>Status</p>
            </div>
            {campaignApprovals?.map((approval, ind) => (
                <div className="flex flex-col pt-4 pb-4 w-full font-medium border-b last:pb-0 first:pt-0 last:border-b-0">
                    <div className="flex justify-between items-center w-full font-medium">
                        <p>{approval?.location?.name}</p>
                        <ToggleInput
                            control={control}
                            name={`approvals.${ind}.approved`}
                            displayLabels={false}
                            className="mt-0 !w-min"
                            inputClassName="!mt-0"
                            onChangeCallback={(val) => {
                                toggleInputFor(ind, val);
                            }}
                            toggleProps={{ vibrant: true }}
                        />
                    </div>
                    {/* TODO: form text input */}
                    {inputActive?.[ind] && (
                        <Input
                            control={control}
                            name={`approvals.${ind}.message`}
                            error={errors?.approvals?.[ind]?.message}
                            className="mt-3 sm:w-full"
                            placeholder="Adicione uma justificativa para não aprovação"
                            showLabel={false}
                            min={3}
                            minLength={3}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};
export default ApprovalsDisplayForm;
