import { FC, ReactNode } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { AccentButton, ErrorMessage, Input } from '../../../../common/components';

interface Props {
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    isEdit?: boolean;
}

const AdvertisingSegmentForm: FC<Props> = ({
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <Section>
                <DescriptionColumn
                    title="Segmentos de publicidade"
                    description="Segmentos para organizar campanhas de 
                    publicidade"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="name"
                        label="Nome"
                        error={errors?.title}
                        className="sm:w-full"
                    />
                </FormColumn>
            </Section>
            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                    Cancelar
                </Link>
                <AccentButton type="submit" loading={loading} className="px-4 py-2 ml-3 w-max">
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 w-full md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 w-full md:col-span-2 md:mt-0">{children}</div>;
};

export default AdvertisingSegmentForm;
