import { FC } from 'react';
import { Pagination, Table } from '../../../common/components';
import { useCampaignApprovals } from '../../../common/hooks';
import { CampaignApprovalPillComponent } from '../../../common/components/StatusPillComponent';
import { DateIntervalComponent } from '../../../common/components/Form';

const PeriodComponent: FC<{ item: CampaignApproval }> = ({ item }) => {
    const start_date = item.campaign.campaign_start_date as string;
    const end_date = item.campaign.campaign_end_date as string;
    return <DateIntervalComponent start={start_date} end={end_date} />;
};

const headers = [
    {
        label: 'Campanha',
        path: 'campaign.name',
    },
    {
        label: 'Ponto',
        path: 'point.name',
    },
    {
        label: 'Período',
        path: 'campaign.id', // not used
        Component: PeriodComponent,
    },
    {
        label: 'Status',
        path: 'status',
        Component: CampaignApprovalPillComponent,
    },
];

interface Props {
    orgId?: string;
}

const CampaignApproval: FC<Props> = ({ orgId }) => {
    const { advertisingContents, fetchCampaignApprovals, loading } = useCampaignApprovals(orgId);

    const changePage = (page: number) => {
        if (page !== advertisingContents?.meta.currentPage) {
            fetchCampaignApprovals({ page: page.toString() });
        }
    };

    const activePath = orgId ? `/orgs/${orgId}` : '';
    const editCampaignApprovalHref = `${activePath}/advertising/approvals/edit`;

    return (
        <>
            <Table
                headers={headers}
                data={advertisingContents?.data || []}
                editPath={editCampaignApprovalHref}
                editButtonLabel="Ver"
                loading={loading}
            />
            <Pagination meta={advertisingContents?.meta} changePage={changePage} />
        </>
    );
};

export default CampaignApproval;
