import { PointAdvertisingLayout } from '../../../../common/components';
import CreateAdvertisingContent from '../../../common/advertising-content/CreateAdvertisingContent';

const PointCreateAdvertisingContent = () => {
    return (
        <PointAdvertisingLayout title="Adicionar conteúdo">
            <CreateAdvertisingContent />
        </PointAdvertisingLayout>
    );
};

export default PointCreateAdvertisingContent;
