import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';
import { FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import ErrorMessage from './ErrorMessage';

type Props = {
    name: string;
    label?: string;
    className?: string;
    innerClassName?: string;
    error?: FieldError;
    children?: ReactNode;
    tooltip?: string;
    showLabel?: boolean;
};

const InputContainer: FC<Props> = ({
    name,
    label,
    className,
    innerClassName,
    error,
    children,
    tooltip,
    showLabel = true,
}) => {
    return (
        <div className={`w-full max-w-full sm:w-96 ${className}`}>
            {showLabel && (
                <label
                    htmlFor={name}
                    className="flex gap-1 text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
                >
                    {label || name}
                    {tooltip && (
                        <Tooltip content={tooltip} className="ml-1">
                            <QuestionMarkCircleIcon height="20" width="20" className="inline" />
                        </Tooltip>
                    )}
                </label>
            )}
            <div className={`mt-2 ${innerClassName}`}>{children}</div>
            <ErrorMessage message={error?.message} />
        </div>
    );
};

export default InputContainer;
