import { PointAdvertisingLayout } from '../../../../common/components';
import UpdateAdvertisingContent from '../../../common/advertising-content/UpdateAdvertisingContent';

const PointUpdateAdvertisingContent = () => {
    return (
        <PointAdvertisingLayout title="Alterar conteúdo de publicidade">
            <UpdateAdvertisingContent />
        </PointAdvertisingLayout>
    );
};

export default PointUpdateAdvertisingContent;
