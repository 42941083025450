import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';
import { useSelector } from 'react-redux';
import { getUser } from '../store';

const useCampaignApprovals = (orgId?: string) => {
    const [advertisingContents, setCampaignApprovals] = useState<{
        meta: any;
        data: any[];
    }>();
    const { get, patch, loading, error } = useRequests();

    const user = useSelector(getUser);
    const activeOrg = orgId || user?.org?.id;

    const fetchCampaignApprovals = async ({ page, search }: FetchParams = {}) => {
        let route = '/partner/campaign-approvals?';
        const activeOrg = orgId || user?.org?.id;
        route += `orgId=${activeOrg}`;

        if (page) {
            route += `page=${page}&`;
        }

        if (search) {
            route += `search=${search}&`;
        }

        try {
            const response = await get(route);
            const data = response?.data;

            setCampaignApprovals(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const updateCampaignApproval = async (id: string, payload: any) => {
        let route = `/partner/campaign-approvals/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Aprovação revisada com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        if (!activeOrg) return;

        fetchCampaignApprovals();

        // eslint-disable-next-line
    }, []);

    return {
        advertisingContents: advertisingContents,
        fetchCampaignApprovals,
        updateCampaignApproval,
        error,
        loading,
    };
};

export default useCampaignApprovals;
