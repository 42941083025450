export function getFileType(fileUrl: string) {
    if (!fileUrl) return null;

    const fileExtension = fileUrl.split('.').pop();
    if (!fileExtension) return null;

    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'tiff', 'webp'];
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm'];

    if (imageExtensions.includes(fileExtension)) {
        return 'image';
    } else if (videoExtensions.includes(fileExtension)) {
        return 'video';
    }

    return null;
}

export async function getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
        // Check if the file is a video
        if (!file.type.startsWith('video/')) {
            resolve(0); // Return 0 for non-video files
            return;
        }

        // Create a video element
        const videoElement = document.createElement('video');
        const fileURL = URL.createObjectURL(file);

        videoElement.src = fileURL;
        videoElement.preload = 'metadata';

        // Load metadata and retrieve the duration
        videoElement.onloadedmetadata = () => {
            const duration = videoElement.duration; // Duration in seconds
            URL.revokeObjectURL(fileURL); // Clean up
            resolve(Math.ceil(duration));
        };

        // Handle errors (e.g., if the file cannot be read as a video)
        videoElement.onerror = () => {
            URL.revokeObjectURL(fileURL); // Clean up
            resolve(0);
        };
    });
}
