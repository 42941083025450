import { FC, ReactNode } from 'react';

import { FilmIcon, ShieldCheckIcon, TvIcon } from '@heroicons/react/24/outline';
import AdminLayout from './AdminLayout';
import SidebarNavigation from './SidebarNavigation';

interface PageContent {
    title?: string;
    children?: ReactNode;
}

const navigation = [
    {
        name: 'Conteúdos',
        href: '/advertising/contents',
        icon: FilmIcon,
    },
    {
        name: 'Campanhas',
        href: '/advertising/campaigns',
        icon: TvIcon,
    },
    {
        name: 'Aprovações',
        href: '/advertising/approvals',
        icon: ShieldCheckIcon,
    },
];

const AdminAdvertisingLayout: FC<PageContent> = ({ children, title }) => {
    return (
        <AdminLayout title={title} navigation={navigation}>
            <div className="flex gap-8">
                <SidebarNavigation navigation={navigation} />
                <div className="flex overflow-y-auto flex-col flex-grow p-8 max-w-full bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminAdvertisingLayout;
