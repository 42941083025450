import { useEffect, useState } from 'react';
import { useRequests } from '.';
import { toast } from '../libs/toast';
import { getErrorMessage } from '../utils/application.utils';
import fileDownload from 'js-file-download';

interface Options {
    fetchData?: boolean;
    fetchOptions?: boolean;
    orgId?: string;
    clientId?: string;
}

type SearchFilters =
    | 'id_search'
    | 'user_search'
    | 'location_id'
    | 'org_id'
    | 'device_origin_search'
    | 'device_return_search'
    | 'battery_search';

// date_from: dateRange.startDate,
// date_to: dateRange.endDate,
// page,

// id_search: idSearch,
// user_search: clientSearch,
// device_origin_search: deviceOriginSearch,
// device_return_search: deviceReturnSearch,
// battery_search: batterySearch,
// status: status?.value as RentStatus,

interface FetchRentsParams extends FetchParams {
    status?: RentStatus;
    date_from?: any;
    date_to?: any;
    search_filters?: {
        [K in SearchFilters]?: string;
    };
}

const useRents = ({ fetchData = false, fetchOptions = false, orgId }: Options = {} as any) => {
    const [rents, setRents] = useState<{ meta: any; data: any[] }>();
    const { get, patch, loading, error } = useRequests();
    const [orgOptions, setOrgOptions] = useState<SelectOption[]>([]);
    const [locationOptions, setLocationOptions] = useState<any[]>([]);

    const fetchRents = async (queryParams: FetchRentsParams = {}) => {
        const route = '/partner/rents?';
        const queryString = buildRentsQueryString(route, queryParams);

        try {
            const response = await get(queryString);
            const data = response?.data;
            setRents(data);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const downloadRents = async (queryParams: FetchRentsParams = {}) => {
        const route = '/partner/rents/csv?';
        const queryString = buildRentsQueryString(route, queryParams);

        try {
            const response = await get(queryString, { responseType: 'blob' });

            // generate query id
            const { search_filters, ...query } = queryParams;
            const query_id = { ...query, ...search_filters };
            const query_id_string = Object.values(query_id).filter((item) => !!item);
            const id = query_id_string.join('_') || 'ALL';

            // generate query filename
            const filename = `rents_${Date.now()}_${id}.xlsx`;

            fileDownload(response.data, filename);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const buildRentsQueryString = (route: string, queryParams: FetchRentsParams) => {
        // add query filter key/values
        const queryKeys: (keyof FetchRentsParams)[] = ['page', 'date_from', 'date_to', 'status'];

        for (const queryKey of queryKeys) {
            const queryValue = queryParams[queryKey];
            if (queryValue) {
                route += `${queryKey}=${queryValue}&`;
            }
        }

        // add query search filter key/values
        const searchFilterKeys: SearchFilters[] = [
            'id_search',
            'user_search',
            'location_id',
            'device_origin_search',
            'device_return_search',
            'battery_search',
        ];

        for (const searchKey of searchFilterKeys) {
            const searchValue = queryParams.search_filters?.[searchKey];
            if (searchValue) {
                route += `${searchKey}=${searchValue}&`;
            }
        }

        const orgFilter = orgId || queryParams.search_filters?.org_id;
        if (orgFilter) {
            route += `orgId=${orgFilter}&`;
        }

        return route;
    };

    const updateRent = async (id: string, payload: any) => {
        let route = `/partner/rents/${id}`;

        try {
            await patch(route, payload);
            setTimeout(() => toast.success('Aluguel alterado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const cancelRent = async (id: string) => {
        let route = `/partner/rents/${id}/cancel`;

        try {
            await patch(route, {});
            setTimeout(() => toast.success('Aluguel cancelado com sucesso!'), 0);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
            throw err;
        }
    };

    const fetchOrgOptions = async () => {
        if (orgId) return;
        let route = '/admin/orgs?perPage=9999&';

        try {
            const response = await get(route);
            const data = response?.data?.data;
            const options = data?.map((org: any) => ({
                value: org.id,
                label: org.name,
            }));
            setOrgOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const fetchLocationOptions = async () => {
        let route = '/partner/locations?perPage=9999&';
        if (orgId) route += `orgId=${orgId}`; // limit to active/selected partner

        try {
            const response = await get(route);
            const data = response?.data?.data;

            const options = buildOptionsGroupedByOrg(data);
            // const options = data?.map((location: any) => ({
            //     value: location.id,
            //     label: location.name,
            // }));

            setLocationOptions(options || []);
        } catch (err: any) {
            const errorMessage = getErrorMessage(err);
            toast.error(errorMessage);
        }
    };

    const buildOptionsGroupedByOrg = (data: any) => {
        // create map of org { id => name }
        const orgNameById: any = {};

        // group items by org
        const byOrg: any = {};
        for (const item of data) {
            if (!byOrg[item.org_id]) {
                byOrg[item.org_id] = [];
            }

            byOrg[item.org_id].push({ value: item.id, label: item.name });
            // orgNameById[item.org_id] = item.org?.name || partnerId || item.org_id;
            orgNameById[item.org_id] = item.org?.name || item.org_id;
        }

        // create react-select grouped options
        const optionGroups = [];
        for (const orgId of Object.keys(byOrg)) {
            optionGroups.push({
                // label: orgNameById[orgId] || partnerId || orgId,
                label: orgNameById[orgId] || orgId,
                options: byOrg[orgId],
            });
        }

        return optionGroups;
    };

    useEffect(() => {
        if (fetchData) {
            fetchRents();
        }

        if (fetchOptions) {
            fetchOrgOptions();
            fetchLocationOptions();
        }

        // eslint-disable-next-line
    }, []);

    return {
        rents,
        orgOptions,
        locationOptions,
        fetchRents,
        updateRent,
        cancelRent,
        downloadRents,
        error,
        loading,
    };
};

export default useRents;
