import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { FC, useMemo } from 'react';
import { formatDate, minutesToFormatedTime, secondsToFormatedTime } from '../../utils/date.utils';

export const TableOrderingButton = () => {
    return (
        <div className="flex justify-center w-full">
            <ChevronUpDownIcon width={24} />
        </div>
    );
};

const TimeParserComponent: FC<{ value: string; parser: (value: number) => string }> = ({
    value,
    parser,
}) => {
    const time = useMemo(() => {
        const seconds = parser(Number(value));
        if (seconds.includes('NaN')) return '-';
        return seconds;
        // eslint-disable-next-line
    }, [value]);

    return <>{time}</>;
};

export const MinutesParserComponent: FC<{ value: string }> = ({ value }) => {
    const parser = minutesToFormatedTime;

    return <TimeParserComponent value={value} parser={parser} />;
};

export const SecondsParserComponent: FC<{ value: string }> = ({ value }) => {
    const parser = secondsToFormatedTime;

    return <TimeParserComponent value={value} parser={parser} />;
};

export const ImagePreviewComponent: FC<{ value: string; item: AdvertisingContent }> = ({
    item,
    value,
}) => {
    if (item.file_type === 'VIDEO') {
        return (
            <div className="overflow-hidden w-[94px] h-[72px] bg-gray-200">
                <video className="object-cover w-full h-full">
                    <source src={value} />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }

    return (
        <div className="overflow-hidden w-[94px] h-[72px] bg-gray-200">
            <img src={value} alt={value} className="object-cover w-full h-full" />;
        </div>
    );
};

export const UpperCaseComponent: FC<{ value: string }> = ({ value }) => {
    return <>{value.toUpperCase()}</>;
};

export const DateTimeSecondsParserComponent: FC<{ value: string }> = ({ value }) => {
    const date = useMemo(() => {
        return formatDate(value, true) || '-';
    }, [value]);

    return <>{date}</>;
};
