import { Calendar, globalizeLocalizer } from 'react-big-calendar';
import globalize from 'globalize';
import { FC, useMemo } from 'react';

require('globalize/lib/cultures/globalize.culture.pt-BR');

const localizer = globalizeLocalizer(globalize);

const messages = {
    week: 'Semana',
    day: 'Dia',
    month: 'Mês',
    previous: 'Anterior',
    next: 'Próximo',
    today: 'Hoje',
    agenda: 'Agenda',
};

interface Props {
    data?: AdvertisingCampaign[];
    onSelectEvent?: (e: any) => void;
}

const advertisignCampaignToEvent = (campaign: AdvertisingCampaign) => {
    return {
        id: campaign.id,
        title: campaign.name,
        start: new Date(campaign.campaign_start_date),
        end: new Date(campaign.campaign_end_date),
    };
};

const AdvertisingCampaignCalendar: FC<Props> = ({ data, onSelectEvent }) => {
    const events = useMemo(() => {
        if (!data) return [];

        // convert campaign information to events

        const _events: any[] = data.map((campaign) => advertisignCampaignToEvent(campaign));

        return _events;
        // eslint-disable-next-line
    }, [data]);

    return (
        <Calendar
            culture="pt-BR"
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            messages={messages}
            style={{ height: 720 }}
            showAllEvents={true}
            onSelectEvent={onSelectEvent}
            views={['month', 'week', 'day']}
        />
    );
};
export default AdvertisingCampaignCalendar;
