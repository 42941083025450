import { FC, ReactNode } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { HintIcon } from '../../../common/assets';
import {
    AccentButton,
    BadgePill,
    ErrorMessage,
    Input,
    NumberInput,
    SelectInput,
    ToggleInput,
} from '../../../common/components';
import accountTypeOptions from '../../../common/data/account-types.json';
import bankOptions from '../../../common/data/banks.json';
import countryOptions from '../../../common/data/countries.json';
import stateOptions from '../../../common/data/states.json';
import InputContainer from '../../../common/components/InputContainer';
import { usePagbank } from '../../../common/hooks';

interface Props {
    bankAccountId?: string;
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    isEdit?: boolean;
    displayTos: boolean;
    watch?: any;
    canConnectPagbank?: boolean;
    pagbankConnected?: boolean;
}

const BankAccountForm: FC<Props> = ({
    bankAccountId,
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
    isEdit = false,
    displayTos = true,
    watch,
    canConnectPagbank,
    pagbankConnected,
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const tos_accepted = watch('tos_accepted');

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            <Section>
                <DescriptionColumn title="Dados do empresa" description="Informações do negócio" />
                <FormColumn>
                    <Input
                        control={control}
                        name="name"
                        label="Nome da empresa / pessoa"
                        error={errors?.name}
                        className="sm:w-full"
                        disabled={isEdit}
                    />
                    <Input
                        control={control}
                        name="document"
                        label="CNPJ / CPF"
                        type="number"
                        error={errors?.document}
                        className="sm:w-full max-w-[13.25rem]"
                        disabled={isEdit}
                    />
                </FormColumn>
            </Section>

            {/* ADDRESS INFO */}
            <Section>
                <DescriptionColumn
                    title="Dados de endereço"
                    description="Informações do endereço da empresa"
                />
                <FormColumn>
                    <NumberInput
                        control={control}
                        name="address.cep"
                        label="CEP"
                        type="number"
                        className="!max-w-[13.25rem]"
                        error={errors?.address?.cep}
                        format="#####-###"
                    />
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="address.street_name"
                            label="Endereço"
                            error={errors?.address?.street_name}
                        />
                        <Input
                            control={control}
                            name="address.street_number"
                            label="Número"
                            type="number"
                            className="!max-w-[5rem]"
                            error={errors?.address?.street_number}
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="address.complement"
                            label="Complemento (opcional)"
                            error={errors?.address?.complement}
                        />
                        <Input
                            control={control}
                            name="address.neighborhood"
                            label="Bairro"
                            error={errors?.address?.neighborhood}
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <SelectInput
                            control={control}
                            name="address.state"
                            label="Estado (UF)"
                            error={errors?.address?.state?.value}
                            options={stateOptions}
                        />
                        <Input
                            control={control}
                            name="address.city"
                            label="Cidade"
                            error={errors?.address?.city}
                        />
                    </div>
                    <SelectInput
                        control={control}
                        name="address.country"
                        label="País"
                        error={errors?.address?.country?.value}
                        className="!max-w-[13.25rem]"
                        options={countryOptions}
                    />
                </FormColumn>
            </Section>

            {/* USER INFO */}
            <Section>
                <DescriptionColumn
                    title="Dados do responsável"
                    description="Informações pessoais do responsável legal da empresa"
                />
                <FormColumn>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="owner.full_name"
                            label="Nome completo"
                            error={errors?.owner?.full_name}
                            className="sm:w-full"
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="owner.first_name"
                            label="Primeiro nome"
                            error={errors?.owner?.first_name}
                            disabled={true}
                        />
                        <Input
                            control={control}
                            name="owner.last_name"
                            label="Sobrenome"
                            error={errors?.owner?.last_name}
                            disabled={true}
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="owner.email"
                            label="E-mail"
                            error={errors?.owner?.email}
                            disabled={isEdit}
                        />
                        <NumberInput
                            control={control}
                            name="owner.phone"
                            label="Telefone"
                            type="number"
                            error={errors?.owner?.phone}
                            disabled={isEdit}
                            format="(##) #####-####"
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="owner.mother_name"
                            label="Nome da mãe"
                            error={errors?.owner?.mother_name}
                        />
                        <NumberInput
                            control={control}
                            name="owner.birth_date"
                            label="Data de nascimento"
                            type="number"
                            error={errors?.owner?.birth_date}
                            format="##/##/####"
                        />
                    </div>
                    <NumberInput
                        control={control}
                        name="owner.cpf"
                        label="CPF"
                        type="number"
                        className="!max-w-[13.25rem]"
                        error={errors?.owner?.cpf}
                        disabled={isEdit}
                        format="###.###.###-##"
                    />
                </FormColumn>
            </Section>

            {/* BANK ACCOUNT INFO */}
            <Section>
                <DescriptionColumn
                    title="Dados bancários"
                    description="Informações da conta bancária da empresa"
                />
                <FormColumn>
                    <SelectInput
                        control={control}
                        name="account.bank_id"
                        label="Banco"
                        error={errors?.account?.bank_id?.value}
                        options={bankOptions}
                        className="sm:w-full"
                    />
                    <SelectInput
                        control={control}
                        name="account.type"
                        label="Tipo de conta"
                        error={errors?.account?.type?.type}
                        options={accountTypeOptions}
                        className="sm:w-full"
                    />
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="account.agency"
                            label="Agência"
                            type="number"
                            error={errors?.account?.agency}
                        />
                        <Input
                            control={control}
                            name="account.agency_digit"
                            label="Dígito (opcional)"
                            type="number"
                            className="!max-w-[8rem]"
                            error={errors?.account?.agency_digit}
                        />
                    </div>
                    <div className="flex gap-6 w-full">
                        <Input
                            control={control}
                            name="account.number"
                            label="Número da conta"
                            type="number"
                            error={errors?.account?.number}
                        />
                        <Input
                            control={control}
                            name="account.number_digit"
                            label="Dígito (opcional)"
                            type="number"
                            className="!max-w-[8rem]"
                            error={errors?.account?.number_digit}
                        />
                    </div>
                    <div className="flex gap-1.5 w-full text-sm leading-5 text-gray-500">
                        <div>
                            <HintIcon />
                        </div>
                        Deixar o campo "Digito" em branco caso não existir Dígito para a Agência ou
                        Número da conta
                    </div>
                </FormColumn>
            </Section>

            {/* TOS Pagbank */}
            <Section>
                <DescriptionColumn
                    title="Conta do PagBank"
                    description="Termos de Serviço da conta PagBank do Pagseguro"
                />
                <FormColumn>
                    {pagbankConnected && <BadgePill color="green">Conectada</BadgePill>}
                    <InputContainer
                        name="tos_accepted"
                        label="Concorda com os termos de serviço do PagBank?"
                        tooltip={
                            displayTos ? '' : 'Apenas o parceiro pode aceitar os termos de serviço'
                        }
                    >
                        <ToggleInput
                            control={control}
                            name="tos_accepted"
                            error={errors?.tos_accepted}
                            label=""
                            labelActive="Sim"
                            labelDisabled="Não"
                            disabled={!displayTos}
                        />
                    </InputContainer>
                    <BankAccountPagbankButton
                        pagbankConnected={pagbankConnected}
                        id={bankAccountId}
                        disabled={!canConnectPagbank}
                    />
                </FormColumn>
            </Section>

            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                    Cancelar
                </Link>
                <AccentButton
                    type="submit"
                    loading={loading}
                    disabled={displayTos && !tos_accepted}
                    className="px-4 py-2 ml-3 w-max"
                >
                    Salvar
                </AccentButton>
            </div>
        </form>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return (
        <div className="relative mt-5 space-y-6 max-w-[28rem] md:col-span-2 md:mt-0">
            {children}
        </div>
    );
};

const BankAccountPagbankButton: FC<{
    id?: string;
    disabled: boolean;
    pagbankConnected?: boolean;
}> = ({ id, disabled, pagbankConnected }) => {
    const { loading, errorMessage, fetchPagbankConnectUrl, forceCreatePagbank } = usePagbank();

    const openNewTab = (url: string) => {
        const newTab = window.open(url, '_blank', 'noopener,noreferrer');
        if (newTab) {
            newTab.focus();
        } else {
            alert('Please allow popups for this website');
        }
    };

    const connectPagbank = async () => {
        if (!id) return;
        const url = await fetchPagbankConnectUrl(id);
        console.log('!', url);
        openNewTab(url);
    };

    const debugCreatePagbank = async () => {
        if (!id) return;
        await forceCreatePagbank(id);
    };

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <InputContainer
                name="pagbank"
                label="Conectar com o PagBank manualmente"
                tooltip="Disponivel após salvar confirmação de termos de serviço"
            >
                {/* {!pagbankConnected && ( */}
                <AccentButton
                    onClick={debugCreatePagbank}
                    loading={loading}
                    color="blue"
                    disabled={disabled}
                >
                    [DEBUG] Criar conta Pagbank
                </AccentButton>
                {/* )} */}
                <AccentButton
                    className="text-sm font-medium text-white !border-none !mt-2"
                    color="green"
                    onClick={connectPagbank}
                    loading={loading}
                    disabled={disabled}
                >
                    Conectar conta PagBank
                </AccentButton>
            </InputContainer>
        </>
    );
};

export default BankAccountForm;
