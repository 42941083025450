import { useSelector } from 'react-redux';
import { PointAdvertisingLayout } from '../../../../common/components';
import CreateAdvertisingCampaign from '../../../common/advertising-campaign/CreateAdvertisingCampaign';
import { getUser } from '../../../../common/store';

const AdminCreateAdvertisingCampaign = () => {
    const user = useSelector(getUser);
    const orgId = user?.org?.id;

    return (
        <PointAdvertisingLayout title="Adicionar campanha">
            <CreateAdvertisingCampaign orgId={orgId} />
        </PointAdvertisingLayout>
    );
};

export default AdminCreateAdvertisingCampaign;
