import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import UpdateAdvertisingCampaign from '../../common/advertising-campaign/UpdateAdvertisingCampaign';
import { getSelectedOrg, getUser } from '../../../common/store';

const PartnerUpdateAdvertisingCampaign = () => {
    const user = useSelector(getUser);
    const userOrgId = user?.org?.id;

    const selectedOrg = useSelector(getSelectedOrg);
    const selectedOrgId = selectedOrg?.id as string;

    return (
        // <PartnerAdvertisingLayout title="Alterar campanha de publicidade">
        <PartnerLayoutWithSidebar>
            <UpdateAdvertisingCampaign orgId={selectedOrgId || userOrgId} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerUpdateAdvertisingCampaign;
