import { useSelector } from 'react-redux';
import { PartnerLayoutWithSidebar } from '../../../common/components';
import CreateAdvertisingCampaign from '../../common/advertising-campaign/CreateAdvertisingCampaign';
import { getSelectedOrg, getUser } from '../../../common/store';

const PartnerCreateAdvertisingCampaign = () => {
    const user = useSelector(getUser);
    const userOrgId = user?.org?.id;

    const selectedOrg = useSelector(getSelectedOrg);
    const selectedOrgId = selectedOrg?.id as string;

    return (
        // <PartnerAdvertisingLayout title="Adicionar campanha">
        <PartnerLayoutWithSidebar>
            <CreateAdvertisingCampaign orgId={selectedOrgId || userOrgId} />
        </PartnerLayoutWithSidebar>
    );
};

export default PartnerCreateAdvertisingCampaign;
