import { PointAdvertisingLayout } from '../../../../common/components';
import AdvertisingContent from '../../../common/advertising-content/AdvertisingContent';

const PointAdvertisingContent = () => {
    return (
        <PointAdvertisingLayout title="Conteúdos de publicidade">
            <AdvertisingContent />
        </PointAdvertisingLayout>
    );
};

export default PointAdvertisingContent;
