import { FC } from 'react';
import { AdvertisignCampaignApprovalPillComponent } from '../../../common/components/StatusPillComponent';

interface Props {
    campaignApprovals?: Partial<CampaignApprovalLocations>[];
}

// display for admin/partner view
const ApprovalsDisplay: FC<Props> = ({ campaignApprovals }) => {
    return (
        <div className="w-full text-sm">
            <div className="flex justify-between pt-4 pb-4 w-full font-bold border-b last:pb-0 first:pt-0 last:border-b-0">
                <p>Local</p>
                <p>Status</p>
            </div>
            {campaignApprovals?.map((approval) => (
                <div className="flex flex-col pt-4 pb-4 w-full font-medium border-b last:pb-0 first:pt-0 last:border-b-0">
                    <div className="flex justify-between w-full font-medium">
                        <p>{approval?.location?.name}</p>
                        {/* TODO: form toggle input */}
                        <p>
                            <AdvertisignCampaignApprovalPillComponent
                                value={approval.status as any}
                            />
                        </p>
                    </div>
                    {/* TODO: form text input */}
                    {approval.message && (
                        <div className="px-4 py-2 mt-3 w-full font-normal bg-gray-100 rounded-md">
                            {approval.message}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
export default ApprovalsDisplay;
