import { createId } from '@paralleldrive/cuid2';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
    Control,
    UseFormGetValues,
    UseFormHandleSubmit,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    AccentButton,
    ErrorMessage,
    FormLine,
    Input,
    NumberInput,
    SelectInput,
    ToggleInput,
} from '../../../common/components';
import { useBankAccounts, useDevices } from '../../../common/hooks';
import { getSelectedOrg } from '../../../common/store';
import { PercentageSplit } from '../../admin/orgs/OrgForm';
import LocationPickerModal from './LocationPickerModal';
import InputContainer from '../../../common/components/InputContainer';

interface Props {
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
    watch: UseFormWatch<any>;
    org?: Org;
    advertisingSegmentOptions: SelectOption[];
    pointOptions: SelectOption[];
}

const PartnerLocationForm: FC<Props> = ({
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
    getValues,
    setValue,
    watch,
    org,
    advertisingSegmentOptions,
    pointOptions,
}) => {
    const orgId = useSelector(getSelectedOrg)?.id as string;
    const { bankAccountOptions } = useBankAccounts({
        fetchDataOptions: true,
        orgId,
    });

    const { deviceOptions } = useDevices({
        fetchDataOptions: true,
        orgId,
    });

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const addSplit = (e: any) => {
        e?.preventDefault();
        let splits = getValues('split_rule.percentage_splits') || [];
        splits = [...splits, { id: createId(), total_percentage: 0, bank_account_id: '' }];
        setValue('split_rule.percentage_splits', splits);
    };

    const removeSplit = (index: number) => {
        let splits = [...(getValues('split_rule.percentage_splits') || [])];
        splits.splice(index, 1);
        setValue('split_rule.percentage_splits', splits);
    };

    const activeSplits: PercentageSplit[] = watch('split_rule.percentage_splits');
    const canRemoveSplits = (activeSplits?.length || 0) > 1;

    const canEditAddress = !!watch('address_name');

    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const closeLocationModal = () => setLocationModalOpen(false);

    const selectAddress = (e: any) => {
        e.preventDefault();
        setLocationModalOpen(true);
    };

    const onPickLocation = ({ name, coords }: { name: string; coords: any }) => {
        setValue('address_name', name);
        setValue('address_lat', coords.lat);
        setValue('address_lon', coords.lng);
    };

    const advertising_point_share_active = watch('advertising_point_share_active');

    const [advertising_org_time_percentage, advertising_org_value_percentage] = watch([
        'advertising_org_time_percentage',
        'advertising_org_value_percentage',
    ]);

    useEffect(() => {
        const advertising_total_time_percentage = Number(org?.advertising_org_time_percentage);

        if (advertising_org_time_percentage > advertising_total_time_percentage) {
            setValue('advertising_org_time_percentage', advertising_total_time_percentage);
            setValue('advertising_point_time_percentage', 0);
        } else if (advertising_org_time_percentage < 0) {
            setValue('advertising_org_time_percentage', 0);
            setValue('advertising_point_time_percentage', advertising_total_time_percentage);
        } else {
            const advertising_point_time_percentage =
                advertising_total_time_percentage - advertising_org_time_percentage;
            setValue('advertising_point_time_percentage', advertising_point_time_percentage);
        }

        // eslint-disable-next-line
    }, [advertising_org_time_percentage]);

    useEffect(() => {
        if (advertising_org_value_percentage > 100) {
            setValue('advertising_org_value_percentage', 100);
            setValue('advertising_point_value_percentage', 0);
        } else if (advertising_org_value_percentage < 0) {
            setValue('advertising_org_value_percentage', 0);
            setValue('advertising_point_value_percentage', 100);
        } else {
            const advertising_point_value_percentage = 100 - advertising_org_value_percentage;
            setValue('advertising_point_value_percentage', advertising_point_value_percentage);
        }

        // eslint-disable-next-line
    }, [advertising_org_value_percentage]);

    console.log({ errors });

    return (
        <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
            <ErrorMessage message={error} />
            {/* NOME */}
            <Section>
                <DescriptionColumn
                    title="Informações do local"
                    description="Nome do local que será apresentado para os clientes"
                />
                <FormColumn>
                    <ToggleInput control={control} name="is_active" error={errors?.is_active} />
                    <Input control={control} name="name" label="Nome" error={errors?.name} />
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Informações de localização"
                    description="As coordenadas serão utilizadas para apresentar os pontos
          no mapa do aplicativo"
                />
                <FormColumn>
                    <div className="flex gap-6 max-w-sm">
                        <AccentButton className="sm:w-auto sm:px-7" onClick={selectAddress}>
                            Selecionar no mapa
                        </AccentButton>
                    </div>
                    <Input
                        control={control}
                        name="address_name"
                        label="Endereço"
                        error={errors?.address_name}
                        disabled={!canEditAddress}
                    />
                    <FormLine>
                        <Input
                            control={control}
                            name="address_lat"
                            label="Latitude"
                            error={errors?.address_lat}
                            disabled={true}
                        />{' '}
                        <Input
                            control={control}
                            name="address_lon"
                            label="Longitude"
                            error={errors?.address_lon}
                            disabled={true}
                        />
                    </FormLine>
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Informações de horário"
                    description="Horário de abertura e fechamento do local"
                />
                <FormColumn>
                    <FormLine>
                        {/* TODO: change to "time" input */}
                        <Input
                            control={control}
                            name="opening_hour"
                            label="Horário de abertura"
                            error={errors?.opening_hour}
                            type="time"
                        />
                        <Input
                            control={control}
                            name="closing_hour"
                            label="Horário de fechamento"
                            error={errors?.closing_hour}
                            type="time"
                        />
                    </FormLine>
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Informações de cobrança"
                    description="Quanto o cliente irá pagar por aluguel"
                />
                <FormColumn>
                    <NumberInput
                        control={control}
                        name="charge_value_first_hour"
                        label="Valor cobrado na primeira hora"
                        error={errors?.charge_value_first_hour}
                        type="number"
                        currency
                        useFloatValue
                    />
                    <NumberInput
                        control={control}
                        name="charge_value_per_hour"
                        label="Valor cobrado por hora"
                        error={errors?.charge_value_per_hour}
                        type="number"
                        currency
                        useFloatValue
                    />
                    <Input
                        control={control}
                        name="charge_free_minutes"
                        label="Minutos gratuitos"
                        error={errors?.charge_free_minutes}
                        type="number"
                        tooltip="Quantos minutos são gratuitos para o usuário em cada aluguel. Informe 0 para indicar que não existem minutos gratuitos."
                    />
                    <Input
                        control={control}
                        name="charge_daily_limit"
                        label="Limite diário de cobrança"
                        error={errors?.charge_daily_limit}
                        type="number"
                        tooltip="Limite máximo de consumo diário em minutos. Informe 0 para indicar que não existe limite de consumo."
                    />
                    <InputContainer
                        name="allow_free_credits"
                        label="Permitir uso de créditos gratuitos?"
                        tooltip="Permitir que o usuário utilize créditos gratuitos nessa máquina."
                    >
                        <ToggleInput
                            control={control}
                            name="allow_free_credits"
                            error={errors?.allow_free_credits}
                            label=""
                            labelActive="Sim"
                            labelDisabled="Não"
                        />
                    </InputContainer>
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Regra de divisão do parceiro"
                    description="Informações das contas que irão receber a comissão"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="split_rule.total_percentage"
                        label="Percentual total de comissão para rateio pelo parceiro"
                        type="number"
                        // min="0"
                        // max="100"
                        error={errors?.split_rule?.total_percentage}
                        disabled={true}
                    />
                    {activeSplits?.map((item, index) => (
                        <div className="flex gap-8" key={item.id}>
                            <div className="flex gap-6 max-w-sm">
                                <Input
                                    control={control}
                                    name={`split_rule.percentage_splits[${index}].total_percentage`}
                                    label="Percentual"
                                    type="number"
                                    min="0"
                                    max="100"
                                    error={
                                        errors?.split_rule?.percentage_splits?.[index]
                                            ?.total_percentage
                                    }
                                />
                                <SelectInput
                                    control={control}
                                    name={`split_rule.percentage_splits[${index}].bank_account_id`}
                                    label="Conta Bancária"
                                    error={
                                        errors?.split_rule?.percentage_splits?.[index]
                                            ?.bank_account_id?.value
                                    }
                                    options={bankAccountOptions}
                                />
                            </div>
                            {canRemoveSplits && (
                                <button
                                    className="flex items-end pb-2 text-red-500 cursor-pointer"
                                    onClick={() => removeSplit(index)}
                                >
                                    Excluir
                                </button>
                            )}
                        </div>
                    ))}
                    <div className="flex gap-6 justify-end max-w-sm">
                        <AccentButton className="sm:w-auto sm:px-7" onClick={addSplit}>
                            Adicionar split
                        </AccentButton>
                    </div>
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Regra de divisão de publicidade"
                    description="Informações de divisão de tempo de publicidade do ponto"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="advertising_total_time_percentage" // valor extraido do org
                        label="Percentual total de tempo de publicidade para rateio"
                        type="number"
                        disabled={true}
                    />

                    <InputContainer
                        name="advertising_point_share_active"
                        label="Dividir tempo de publicidade com ponto?"
                    >
                        <ToggleInput
                            control={control}
                            name="advertising_point_share_active"
                            error={errors?.advertising_point_share_active}
                            label=""
                            labelActive="Sim"
                            labelDisabled="Não"
                        />
                    </InputContainer>

                    <Input
                        control={control}
                        name="advertising_org_time_percentage"
                        label="Percentual de tempo de publicidade do parceiro"
                        type="number"
                        error={errors?.advertising_org_time_percentage}
                        disabled={!advertising_point_share_active}
                    />
                    <Input
                        control={control}
                        name="advertising_point_time_percentage"
                        label="Percentual de tempo de publicidade do ponto"
                        type="number"
                        disabled={true}
                    />
                    <SelectInput
                        control={control}
                        name="point_id"
                        label="Conta do ponto"
                        error={errors?.point_id}
                        options={pointOptions}
                    />

                    <InputContainer
                        name="advertising_point_require_approval"
                        label="Publicidade requer aprovação do ponto?"
                        tooltip="A publicidade nesse local só será veiculada após aprovação do ponto"
                    >
                        <ToggleInput
                            control={control}
                            name="advertising_point_require_approval"
                            error={errors?.advertising_point_require_approval}
                            label=""
                            labelActive="Sim"
                            labelDisabled="Não"
                        />
                    </InputContainer>
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Regra de divisão de valor de publicidade"
                    description="Informações de divisão de valor monetário de publicidade
                    do parceiro e ponto.
                    
                    O percentual de valor do parceiro e ponto precisa 
                    totalizar 100%"
                />
                <FormColumn>
                    <Input
                        control={control}
                        name="advertising_org_value_percentage"
                        label="Percentual de valor de publicidade do parceiro"
                        type="number"
                        error={errors?.advertising_org_value_percentage}
                    />
                    <Input
                        control={control}
                        name="advertising_point_value_percentage"
                        label="Percentual de valor de publicidade do ponto"
                        type="number"
                        error={errors?.advertising_point_value_percentage}
                        disabled={true}
                    />
                </FormColumn>
            </Section>
            <Section>
                <DescriptionColumn
                    title="Segmentação de publicidade"
                    description="Segmento associado a esse local"
                />
                <FormColumn>
                    <SelectInput
                        control={control}
                        name="advertising_segment_id"
                        label="Segmento"
                        error={errors?.advertising_segment_id}
                        options={advertisingSegmentOptions}
                    />
                </FormColumn>
            </Section>

            <Section>
                <DescriptionColumn
                    title="Máquinas"
                    description="Máquinas vinculadas a esse local"
                />
                <FormColumn>
                    <SelectInput
                        control={control}
                        name="devices"
                        label="Números de série"
                        error={errors?.devices}
                        options={deviceOptions}
                        multi
                    />
                </FormColumn>
            </Section>
            <div className="flex justify-end items-center pt-7 text-sm">
                <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                    Cancelar
                </Link>
                <AccentButton type="submit" loading={loading} className="px-4 py-2 ml-3 w-max">
                    Salvar
                </AccentButton>
            </div>
            <LocationPickerModal
                onSubmitSelection={onPickLocation}
                open={locationModalOpen}
                closeModal={closeLocationModal}
            />
        </form>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 md:grid md:grid-cols-2 lg:grid-cols-5 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string;
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    return (
        <div className="md:col-span-1 lg:col-span-2">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <p className="mt-4 text-sm text-gray-500">{description}</p>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return (
        <div className="mt-5 space-y-6 max-w-[28rem] md:col-span-2 lg:col-span-3 md:mt-0">
            {children}
        </div>
    );
};

export default PartnerLocationForm;
