import {
    ArrowsPointingInIcon,
    Battery100Icon,
    ChartBarSquareIcon,
    ClipboardDocumentCheckIcon,
    CurrencyDollarIcon,
    MapPinIcon,
    Squares2X2Icon,
    UsersIcon,
    FilmIcon,
    ShieldCheckIcon,
    TvIcon,
} from '@heroicons/react/24/outline';
import { FC, ReactNode, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { getSelectedOrg } from '../store';
import AdminLayout from './AdminLayout';
import SidebarNavigation from './SidebarNavigation';

interface PageContent {
    children?: ReactNode;
}

const generateNavigation = (id: string) => [
    { name: 'Máquinas', href: `/orgs/${id}/devices`, icon: Squares2X2Icon },
    { name: 'Baterias', href: `/orgs/${id}/batteries`, icon: Battery100Icon },
    { name: 'Locais', href: `/orgs/${id}/locations`, icon: MapPinIcon },
    {
        name: 'Contas bancárias',
        href: `/orgs/${id}/bank-accounts`,
        icon: CurrencyDollarIcon,
    },
    {
        name: 'Pedidos',
        href: `/orgs/${id}/rents`,
        icon: ClipboardDocumentCheckIcon,
    },
    { name: 'Usuários', href: `/orgs/${id}/users`, icon: UsersIcon },
    { name: 'Financeiro', href: `/orgs/${id}/transactions`, icon: ChartBarSquareIcon },
    { label: 'Publicidade', type: 'divider' },
    { name: 'Pontos', href: `/orgs/${id}/advertising/points`, icon: ArrowsPointingInIcon },
    {
        name: 'Conteúdos',
        href: `/orgs/${id}/advertising/contents`,
        icon: FilmIcon,
    },
    {
        name: 'Campanhas',
        href: `/orgs/${id}/advertising/campaigns`,
        icon: TvIcon,
    },
    {
        name: 'Aprovações',
        href: `/orgs/${id}/advertising/approvals`,
        icon: ShieldCheckIcon,
    },
];

const PartnerLayoutWithSidebar: FC<PageContent> = ({ children }) => {
    const selectedOrg = useSelector(getSelectedOrg);

    const navigation = useMemo(() => {
        return generateNavigation(selectedOrg?.id || '#');
    }, [selectedOrg]);

    const title = selectedOrg?.name;

    return (
        <AdminLayout title={title} navigation={navigation}>
            <div className="flex gap-8">
                <SidebarNavigation navigation={navigation} />
                <div className="flex overflow-y-auto flex-col flex-grow p-8 max-w-full bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </AdminLayout>
    );
};

export default PartnerLayoutWithSidebar;
